.fetchingStatus::before {
  content: '';
  width: 30px;
  height: 30px;
  background-color: #aaa;
  border-radius: 50%;
  display: inline-block;
  position: relative;
  margin-right: 10px;
}
.runningStatus::before {
  content: '';
  width: 30px;
  height: 30px;
  background-color: #82ca9d;
  border-radius: 50%;
  display: inline-block;
  position: relative;
  margin-right: 10px;
}
.stoppedStatus::before {
  content: '';
  width: 30px;
  height: 30px;
  background-color: red;
  border-radius: 50%;
  display: inline-block;
  position: relative;
  margin-right: 10px;
}

.fetchingStatus,
.runningStatus,
.stoppedStatus {
  margin-bottom: 20px;
  display: flex;
  align-items: center;
}
