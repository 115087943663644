.App {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  height: 100vh;
}

/* Pages */
.page {
  flex: 1;
  height: 100%;
  overflow: scroll;
  box-sizing: border-box;
  padding: 0 20px 200px 20px;
}

/* actions */
.action {
  text-decoration: underline;
  cursor: pointer;
  color: rgb(0, 171, 169);
}
.action:hover {
  color: rgb(0, 136, 134);
  font-style: italic;
}

p {
  white-space: pre-line;
}


/*Licences*/
.redrow td {
  background-color: #D0312D;
  color: white;
}
.orangerow td {
  background-color: orange;
  color: white;
}
.greyrow td {
  background-color: grey;
  color: white;
}
