nav {
  flex-shrink: 0;
  border-right: 3px solid rgb(0, 171, 168);
  overflow: scroll;
  padding-bottom: 40px;
  max-width: 230px;
}

.navItem {
  display: block;
  padding: 16px;
  text-decoration: none;
  color: black;
  border-bottom: 1px solid #eee;
}
.navItem:first-of-type {
  border-top: 1px solid #eee;
}
.navItem:hover,
.navItem.current:hover {
  background-color: rgb(0, 150, 147);
  color: white;
}
.navItem.current {
  background-color: rgb(0, 171, 168);
  color: white;
}

.logo {
  margin-top: 50px;
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.logo svg {
  width: 30px;
  margin-bottom: 5px;
}

.groupLink {
  display: block;
  padding: 10px 20px;
  color: rgb(44, 82, 132);
  cursor: pointer;
  text-decoration: none;
}
.groupLink:not(:last-child) {
  border-bottom: 1px solid rgb(44, 82, 132);
}
.groupLink:hover {
  background-color: rgb(44, 82, 132);
  color: white;
}

.currentGroup {
  text-align: center;
  border-radius: 5px;
  padding: 5px 10px;
  color: white;
  background-color: rgb(44, 83, 132);
  cursor: pointer;
  width: 100%;
}
.superadmin {
  background-color: rgb(246, 164, 0);
}
.groupLink.superadmin:hover {
  background-color: rgb(207, 140, 5);
}
