/* CSS reset fro tables */
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
  border: 1px solid #eee;
}

/* My Style */
td,
th {
  padding: 0.5rem;
  text-align: left;
  color: #555;
  border-collapse: collapse;
  border-left: 1px solid #eee;
  border-right: 1px solid #eee;
}
td {
  border-bottom: 1px solid rgb(0, 171, 169);
}
td.requires_attention {
  background-color: #f6a400;
}

tbody tr:hover:not(.detailContainer) {
  background: #f4f4f4;
}
tbody tr:hover:not(.detailContainer) td {
  color: black;
}

th {
  background-color: rgb(0, 171, 169);
  color: white;
}

/* Details */
.detailContainer {
  background-color: rgb(0, 171, 169, 0.1);
}
table tr.detailContainer table {
  background-color: white;
  border-bottom-width: 1px;
  border-bottom-color: #eee;
}

table tr.detailContainer td,
table tr.detailContainer th {
  border-top-width: 1px;
  border-top-color: #eee;
  padding: 0.2rem 0.5rem;
}

table tr.detailContainer,
table tr.detailContainer > td {
  border-top-color: #eee;
}

/* Editable cell */
td input:not([type='checkbox']) {
  height: 100%;
  border: 0;
  outline: none;
  box-shadow: none;
  border-bottom: 1px solid rgb(0, 171, 169);
  background: none;
  font-size: 16px;
}

/* Table for alignment purposes only */
.invisibleTable,
.invisibleTable td,
.invisibleTable th {
  border: 0;
  padding: 0.1rem 0;
}

.invisibleTable td input {
  border-width: 1px;
  border-style: solid;
  border-color: grey;
  border-radius: 2px;
}
