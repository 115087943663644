.highlighter {
  position: relative;
  padding-left: 50px;
}
.highlighter:before {
  content: '!!';
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  background-color: #ffc658;
  color: white;
  border-radius: 50%;
  padding: 10px;
  animation-name: highlighting;
  animation-duration: 1000ms;
  animation-iteration-count: 5;
  z-index: -1;
}

@keyframes highlighting {
  0% {
    top: 0;
  }
  13% {
    top: 20px;
  }
  25% {
    top: 0;
  }
  37% {
    top: 20px;
  }
  50% {
    top: 0;
  }
  100% {
    top: 0;
  }
}
