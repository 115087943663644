/*  extracts */
.extractAction {
  display: inline-block;
  background-color: rgb(44, 83, 132);
  color: white;
  padding: 2px 10px;
  border-radius: 3px;
  cursor: pointer;
  text-decoration: none;
}

li {
  margin-bottom: 10px;
}

.extractorConfig {
  display: flex;
  align-items: center;
}
.extractorConfig input[type='checkbox'] {
  margin-right: 5px;
}
.extractorConfig input[type='number'] {
  margin: 0 1em;
  width: 3em;
}
.extractorConfig input[type='number']::-webkit-inner-spin-button {
  opacity: 1;
}


/* mail */
input[type="radio"] {
  margin-right: 10px;
  cursor: pointer;
}
