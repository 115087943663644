.paginationBar {
  display: flex;
  align-items: center;
  margin: 10px 0;
}
.paginationArrow {
  box-sizing: border-box;
  padding: 0 10px;
  flex: 0;
  cursor: pointer;
}
.paginationArrow:hover {
  font-weight: bold;
  text-decoration: underline;
  text-decoration-thickness: 2px;
}

.pageIndex {
  padding: 0 0.2rem;
  text-decoration: underline;
  color: rgb(0, 171, 169);
  cursor: pointer;
}
.pageIndex.current {
  color: black;
  text-decoration: none;
  font-weight: bold;
  cursor: default;
}
.pageIndex:not(.current):hover {
  font-style: italic;
  color: rgb(0, 136, 134);
  text-decoration-thickness: 2px;
}
