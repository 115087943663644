.link {
  color: rgb(44, 83, 132);
}
.bold {
  font-weight: bold;
}

.recommendedParam {
  margin: 5px;
  color: white;
  background-color: green;
  border-radius: 3px;
  padding: 3px 6px;
  text-transform: uppercase;
}

.unrecommendedParam {
  margin: 5px;
  color: white;
  background-color: orange;
  border-radius: 3px;
  padding: 3px 6px;
  text-transform: uppercase;
}
.defaultParam {
  margin: 5px;
  color: white;
  background-color: lightgrey;
  border-radius: 3px;
  padding: 3px 6px;
}

.button {
  display: inline-block;
  border: 1px solid rgb(44, 83, 132);
  color: rgb(44, 83, 132);
  border-radius: 5px;
  padding: 10px;
  cursor: pointer;
}
.button:hover {
  background-color: rgb(44, 83, 132);
  color: white;
}
.danger-button {
  display: inline-block;
  border: 1px solid red;
  color: red;
  border-radius: 5px;
  padding: 10px;
  cursor: pointer;
}
.danger-button:hover {
  background-color: red;
  color: white;
}
